import React from "react";
import n1 from "./media/n1.png";
import n2 from "./media/n2.png";
import n3 from "./media/n3.png";
import n4 from "./media/n4.png";
import n5 from "./media/n5.png";
import p1 from "./media/p1.png";
import p2 from "./media/p2.png";
import p3 from "./media/p3.png";
import p4 from "./media/p4.png";
import p5 from "./media/p5.png";
import candidatos from "./media/candidatos.png";

export default function Propuestas() {
  return (
    <div className="propuesta_grande">
      <div className="contenedor_propuestas">
        <div className="titulopropuesta">
          <h2 className="titulopropuesta1">PROPUESTAS CLAVE</h2>
          <h2 className="titulopropuesta2">PARA TUNGURAHUA</h2>
        </div>

        <div className="contenedorpropuesta">
          <div className="cincuenta contenedorpropuestaizquierda">
            <div className="cinco">
              <img src={n1} className="img_numero" alt="uno" />
            </div>
            <div className="noventaycinco">
              <h3>Bandera Blanca por Tungurahua: Mesa de Trabajo Provincial</h3>
              <p className="texto_propu">
                <span className="resaltado">Propuesta: </span>Mesa mensual de
                representantes electos para abordar seguridad, empleo y salud.
              </p>
              <img src={p1} className="img_propu" alt="unop" />
            </div>
          </div>
          <div className="cincuenta contenedorpropuestaderecha">
            <p className="texto_propu">
              <span className="resaltado">Impacto:</span>
            </p>
            <ul>
              <li>Impulsar emprendimientos y fortalecer la economía.</li>
              <li>Generar empleo inclusivo.</li>
              <li>Diseñar estrategias para mejorar la seguridad.</li>
              <li>
                Optimizar infraestructura de salud en zonas rurales y urbanas.
              </li>
            </ul>
          </div>
        </div>

        <div className="separador"></div>

        <div className="contenedorpropuesta">
          <div className="cincuenta contenedorpropuestaizquierda">
            <div className="cinco">
              <img src={n2} className="img_numero" alt="uno" />
            </div>
            <div className="noventaycinco">
              <h3>Ley de Inclusión Laboral Universal</h3>
              <p className="texto_propu">
                <span className="resaltado">Propuesta: </span>Políticas para
                empleo digno y accesible, priorizando grupos vulnerables y
                sectores clave.
              </p>
              <img src={p2} className="img_propu" alt="unop" />
            </div>
          </div>
          <div className="cincuenta contenedorpropuestaderecha">
            <p className="texto_propu">
              <span className="resaltado">Impacto:</span>
            </p>
            <ul>
              <li>
                Inclusión laboral y oportunidades para grupos vulnerables.
              </li>
              <li>Empleo sostenible en seguridad y salud pública.</li>
              <li>Incentivos para contratación inclusiva.</li>
            </ul>
          </div>
        </div>

        <div className="separador"></div>

        <div className="contenedorpropuesta">
          <div className="cincuenta contenedorpropuestaizquierda">
            <div className="cinco">
              <img src={n3} className="img_numero" alt="uno" />
            </div>
            <div className="noventaycinco">
              <h3>Ley Integral de Seguridad Rural y Ciudadana</h3>
              <p className="texto_propu">
                <span className="resaltado">Propuesta: </span>Fortalecer
                seguridad con tecnología, brigadas comunitarias y empleo
                juvenil.
              </p>
              <img src={p3} className="img_propu" alt="unop" />
            </div>
          </div>
          <div className="cincuenta contenedorpropuestaderecha">
            <p className="texto_propu">
              <span className="resaltado">Impacto:</span>
            </p>
            <ul>
              <li>Reducción de delitos en zonas rurales y urbanas.</li>
              <li>Mayor confianza en la seguridad pública.</li>
              <li>
                Protección para agricultores con patrullajes estratégicos.
              </li>
              <li>Generación de empleo en seguridad comunitaria.</li>
            </ul>
          </div>
        </div>

        <div className="separador"></div>

        <div className="contenedorpropuesta">
          <div className="cincuenta contenedorpropuestaizquierda">
            <div className="cinco">
              <img src={n4} className="img_numero" alt="uno" />
            </div>
            <div className="noventaycinco">
              <h3>Ley de Empoderamiento Femenino</h3>
              <p className="texto_propu">
                <span className="resaltado">Propuesta: </span>Modernizar la
                educación para incluir habilidades prácticas y tecnología en
                zonas rurales.
              </p>
              <img src={p4} className="img_propu" alt="unop" />
            </div>
          </div>
          <div className="cincuenta contenedorpropuestaderecha">
            <p className="texto_propu">
              <span className="resaltado">Impacto:</span>
            </p>
            <ul>
              <li>Incremento en la participación económica de mujeres.</li>
              <li>Reducción de desigualdades sociales y económicas.</li>
              <li>Acceso a programas de salud mental y legal para víctimas.</li>
              <li>Creación de empleos liderados por mujeres.</li>
            </ul>
          </div>
        </div>

        <div className="separador"></div>

        <div className="contenedorpropuesta">
          <div className="cincuenta contenedorpropuestaizquierda">
            <div className="cinco">
              <img src={n5} className="img_numero" alt="uno" />
            </div>
            <div className="noventaycinco">
              <h3>Ley de Educación para el Futuro</h3>
              <p className="texto_propu">
                <span className="resaltado">Propuesta: </span>Modernizar la
                educación con tecnología, habilidades prácticas y salud
                preventiva.
              </p>
              <img src={p5} className="img_propu" alt="unop" />
            </div>
          </div>
          <div className="cincuenta contenedorpropuestaderecha">
            <p className="texto_propu">
              <span className="resaltado">Impacto:</span>
            </p>
            <ul>
              <li>Reducción del desempleo juvenil.</li>
              <li>Fomento del emprendimiento tecnológico.</li>
              <li>Equidad educativa entre zonas rurales y urbanas.</li>
              <li>Promoción de salud preventiva desde la escuela.</li>
            </ul>
          </div>
        </div>
      </div>
      <img src={candidatos} className="img_candidatos" alt="unop" />
    </div>
  );
}
